import React from "react";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import slider1 from '../assets/aiotimg.jpg';
import network from '../assets/network.jpg';
import ai from '../assets/ai.jpg';
import vnr from '../assets/vnr.JPG';

const slides = [
  { title: `IEEE SPS Summer School on 
  Internet of Things for Biomedical and Healthcare Applications`,
   description: '28th - 31st December, 2020', button: 'Register Now', image: slider1,
  link: "/registration", button2: "See Brochure", link2: vnr},
  { title: `IEEE Student and graduate student members 
    can join SPS for 1$ for 2021`, button: 'Join Now', image: network,
    link: "https://signalprocessingsociety.org/newsletter/2018/08/one-dollar-endless-opportunity-become-sps-student-member-1"},
  { title: `Submit Your Poster Today 
  Submission Deadline is 26 December 2020`, button: 'Submit Now', image: ai,
    link: "https://docs.google.com/forms/d/e/1FAIpQLSeif98BNgFHr3yEIqwzZg1rHw4QaJ8ayKVnoZ0E-ZqYL2e7wQ/viewform?usp=sf_link", 
    button2: "See Guidelines", link2: vnr},
  { title: `IEEE SPS Summer School 2020 is being organized by 
  VNR Vignana Jyothi Institute of Engineering and Technology
  in association with IEEE Hyderabad Section,
  IEEE Signal Processing Society and IEEE WIE AG`,
   description: '', button: 'Visit Site', image: vnr,
   link: "http://www.vnrvjiet.ac.in/"},
  
];

const Header = () => (
  <div>
    <Slider 
    autoplay={1800}
    >
	{slides.map((item, index) => (
		<div className="main-img"
			key={index}
			style={{ background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url('${item.image}') no-repeat top center`,backgroundSize: 'cover' }}
		>        
      <div className="second px-4">{item.title}</div>
      <div className="my-4">{item.description}</div>
      <a href={item.link} target="_blank" className="link-btn">
        <button className="mainbtn btn-primary">{item.button}</button>
      </a>
      { item.button2 ? <a href={item.link2} target="_blank" className="link-btn ml-5">
        <button className="mainbtn btn-primary btn-info">{item.button2}</button>
      </a> : null}
      
		</div>
	))}
</Slider>
  </div>
);

export default Header;
