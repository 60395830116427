import React from "react";
import { Jumbotron } from "reactstrap";

const Footer = props => {
  return (
    <div>
      <Jumbotron fluid className="bg-dark py-0 my-0">
        <div className="row no-gutters">
          <div className="col-sm-12">
            <div className="team-member">
              <p
                className="font-weight-bold text-center text-white"
                style={styles}
              >
                Follow Us
              </p>
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                  <a href="https://twitter.com/ieee_vnr/">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/ieeevnrvjiet">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/ieee_vnrvjiet/">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-sm-12">
            <h5 className="text-white text-center font-weight-bold p-4">
              IEEE &copy; All Rights Reserved
            </h5>
          </div>
        </div>
      </Jumbotron>
    </div>
  );
};

const styles = {
  paddingTop : "2rem",
  fontSize: "1.5rem"
};

export default Footer;
