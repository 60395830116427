import React from "react";
import ScheduleRow from "./ScheduleRow"
import { Table } from "reactstrap";

const Schedule = props => {
  return (
    <div className="container">
      <div>
        <h2 className="my-4">Schedule</h2>
      </div>
      <hr className="my-4" color="primary" />
      <Table hover responsive>
        <tbody>
          <tr>
            <td></td>
            <td style={{textAlign: 'center',fontWeight: '700'}}>28-Dec-2020</td>
            <td></td>
          </tr>          
          <ScheduleRow 
            topic="Inauguration"
            time="9:30Hrs-10:00Hrs"
          />
          <ScheduleRow 
            topic="Keynote Talk by Dr. M. Rama Subba Reddy-Professor, IIT, Chennai"
            time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow 
              session="Session-1"
              topic="Latest trends in Healthcare technologies"
              speaker="Dr. M. Malini-Professor,Osmania University"
              time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow 
              session="Session-2"
              topic="Bio-Medical Signal Processing techniques"
              speaker="Dr. Celia shahnaz-Professor,Bangladesh University"
              time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow 
              session="Session-3"
              topic="Main Sources of Noise and artifact removal in Biomedical Signals"
              speaker="Dr. Anitha Sheela Kancharla -Professor,JNTUH"
              time="15:45Hrs-17:15Hrs"
          />

          <tr>
            <td></td>
            <td style={{textAlign: 'center',fontWeight: '700'}}>29-Dec-2020</td>
            <td></td>
          </tr>       
          <ScheduleRow 
              session="Session-4"
              topic="Fog computing/Edge clouds for Healthcare"
              speaker="Dr. Rashmi Ranjan Rout-NIT Warangal"
              time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow 
              session="Session-5"
              topic="Sensors for IoT Healthcare"
              speaker="Dr. Neeraj Sharma-Professor, IIT(BHU)"
              time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow 
              session="Session-6"
              topic="Architecture of IoT Healthcare"
              speaker="Dr.Jugnu Jain-Co-Founder & CEO,
              Sapien Biosciences Hyderabad"
              time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow 
              session="Session-7"
              topic="AI for Oncology"
              speaker="Mr.K.Dinesh-CEO, Onward Health,Hyderabad"
              time="15:45Hrs-17:15Hrs"
          />
          <tr>
            <td></td>
            <td style={{textAlign: 'center',fontWeight: '700'}}>30-Dec-2020</td>
            <td></td>
          </tr>       
          <ScheduleRow 
              session="Session-8"
              topic="Fog computing/Edge clouds for Healthcare"
              speaker="Mr.K.Vamsi-CEO,Syntizen, Hyderabad"
              time="09:30Hrs-10:45Hrs"
          />
          <ScheduleRow 
              session="Session-9"
              topic="Machine Learning for Breast cancer diagnosis"
              speaker="Mr.Siva Teja Kakileti – Niramai Health Analytix Pvt Ltd, Bangalore"
              time="10:45Hrs-11:30Hrs"
          />
          <ScheduleRow 
              session="Session-10"
              topic="Ethics for Health-care applications"
              speaker="Dr.Kiran Chakravartula-Associate Professor,VNRVJIET"
              time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow 
              session="Session-11"
              topic="Mobile-to-cloud computing (MCC) for Healthcare"
              speaker="Mr. Sridhar CV-Tata Consultancy Services Hyderabad"
              time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow 
              session="Session-12"
              topic="Case Studies of Internet of Things enabled Healthcare Systems"
              speaker="Mr.P.Sridhar- CEO, Blaze Automation Inc,Hyderabad"
              time="15:45Hrs-17:15Hrs"
          />
          <tr>
            <td></td>
            <td style={{textAlign: 'center',fontWeight: '700'}}>31-Dec-2020</td>
            <td></td>
          </tr>       
          <ScheduleRow 
              session="Session-13"
              topic="Wearable IoT sensing for Health-care"
              speaker="Dr.Bikash Chandra Sahana-Assistant Professor,NIT Patna"
              time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow 
              session="Session-14"
              topic="EEG & ECG Signal Processing Techniques"
              speaker="Dr.Y.Padma Sai-Chair, WIE IEEE Hyderabad Section
              Prof - Head, ECE &Dean Students Progression, VNR VJIET"
              time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow 
              session="Session-15"
              topic="Panel Discussion on Challenges in Establishing Start-up in the Healthcare Domain"
              time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow 
              session="Session-16"
              topic="Online Quiz followed by Valedictory Keynote talk on Recent Emerging Trends in Biomedical Engineering and Healthcare"
              speaker="Dr. N. C. Shiva Prakash"
              time="15:45Hrs-17:15Hrs"
          />
        </tbody>
      </Table>
      <div>
        <h4 className="my-4 font-weight-bold">Other Highlights</h4>
        <ul>
          <li><strong>Poster Presentations on Biomedical Applications from 6.30 PM to 8.30PM on 29th December 2020</strong>
            <ul>
              <li><strong>Chairs</strong></li>
              <li>Dr.K.Anuradha, Professor-EEE, Dean-Academics, VNRVJIET</li>
              <li>Dr.R.Manjula Sri, Professor & HoD-EIE, VNRVJIET</li>
              <li>Dr.G.Ramesh Chandra- Professor-CSE, Head-RCC,VNRVJIET</li>
            </ul>
          </li>
          <li><strong>Live Demonstration 30th December 2020 from 6.30PM to 8.00 PM on 30th December 2020</strong>
            <ul>
              <li>Prosthetic Hand based on voice commands by Mr. N.Mano Satya Sai, CEO,Solbots Technologies Pvt.Ltd during 6.30PM-7 PM</li>
              <li>Digital Health Advisory System for Chronic Respiratory Diseases by Mr. S.V.Narayana Rao, Co-founder / Director, 
                        Salcit Technologies during 7PM - 7. 30 PM </li>
              <li>Various IoT boards by Mr.D. Ramesh Reddy, Asst.Professor,VNRVJIET during 7.30 PM - 8.30PM</li>
            </ul>
          </li>
          <li><strong>Panel Discussion on Challenges in Establishing Start-up in the Healthcare Domain</strong>
      <div className="my-3">
        <h4 className="my-4 font-weight-bold">Panellists</h4>
        <ol>
          <li>
            <div><strong>Dr. Aynampudi. Subbarao</strong></div>
            <div>Innovation Consultant, Director Academics, IFIA</div>
          </li>
          <li>
            <div><strong>Dr. Amit Kumar</strong></div>
            <div>Chair - IEEE Hyderabad Section</div>
            <div>Industry Relations Committee Member - IEEE Region 10</div>
            <div>N & A Committee Member (R7-R10) - IEEE MGA</div>
            <div>Chairman- BioAxis DNA Research Centre (P) Ltd.</div>
          </li>
          <li>
            <div><strong>Dr.Ramjee Pallela</strong></div>
            <div>COO at AIC-CCMB, 
                      Hyderabad</div>
          </li>
          <li>
            <div><strong>Mr. S.V. Narayana Rao</strong> </div>
            <div>Co-founder / Director at Salcit Technologies
                  Hyderabad</div>
          </li>
        </ol>
        <div>Moderator - <strong>Dr.Harivardhagini Subhadra</strong></div>
        <div className="ml-5">Vice-Chair ,WIE AG IEEE Hyderabad Section</div>
      </div></li>
      <li><strong>Panel Discussion on Challenges in Establishing Start-up in the Healthcare Domain</strong>
      <div className="my-3">
        <h4 className="my-4 font-weight-bold">Panellists</h4>
        <ol>
          <li>
            <div><strong>Dr. Aynampudi. Subbarao</strong></div>
            <div>Innovation Consultant, Director Academics, IFIA</div>
          </li>
          <li>
            <div><strong>Dr. Amit Kumar</strong></div>
            <div>Chair - IEEE Hyderabad Section</div>
            <div>Industry Relations Committee Member - IEEE Region 10</div>
            <div>N & A Committee Member (R7-R10) - IEEE MGA</div>
            <div>Chairman- BioAxis DNA Research Centre (P) Ltd.</div>
          </li>
          <li>
            <div><strong>Dr.Ramjee Pallela</strong></div>
            <div>COO at AIC-CCMB, 
                      Hyderabad</div>
          </li>
          <li>
            <div><strong>Mr. S.V. Narayana Rao</strong> </div>
            <div>Co-founder / Director at Salcit Technologies
                  Hyderabad</div>
          </li>
        </ol>
        <div>Moderator - <strong>Dr.Harivardhagini Subhadra</strong></div>
        <div className="ml-5">Vice-Chair ,WIE AG IEEE Hyderabad Section</div>
      </div></li>
        </ul>
      </div>
    </div>
  );
};

export default Schedule;