import React from 'react';
import { Table } from "reactstrap";

export default ()=>(
<section className="bg-light page-section pt-2" id="team">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Student Members</h2>
            
          </div>
        </div>
        <Table hover responsive>
            <tbody>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Position</th>
            </tr>
            <tr>
                <th scope="row">1</th>
                <td>Tharun Kumar Tallapalli</td>
                <td>Chair, IEEE VNR SB</td>
            </tr>
            <tr>
                <th scope="row">2</th>
                <td>Ram Pentapati</td>
                <td>Chair, IEEE VNR SPS SBC</td>
            </tr>
            <tr>
                <th scope="row">3</th>
                <td>Mehrunnisa Begum</td>
                <td>Vice-Chair, IEEE VNR SPS SBC</td>
            </tr>
            <tr>
                <th scope="row">4</th>
                <td>Amruth Raj </td>
                <td>Secretary, IEEE VNR SPS SBC</td>
            </tr>
            <tr>
                <th scope="row">5</th>
                <td>Praneeth Achanta</td>
                <td>Vice-Chair, IEEE VNR SB</td>
            </tr>
            <tr>
                <th scope="row">6</th>
                <td>P. Prathyusha</td>
                <td>Chair, IEEE VNR PES SBC</td>
            </tr>
            <tr>
                <th scope="row">7</th>
                <td>Lavanya Madineni</td>
                <td>Chair, IEEE VNR CASS SBC</td>
            </tr>
            <tr>
                <th scope="row">8</th>
                <td>
                Saadia Hassan
                </td>
                <td>Chair, IEEE VNR WIE AG</td>
            </tr>
            <tr>
                <th scope="row">9</th>
                <td>I. Rajeshwari</td>
                <td>Vice-Chair, IEEE VNR CS SBC</td>
            </tr>
            <tr>
                <th scope="row">10</th>
                <td>Damodar Darshan Kolla</td>
                <td>Secretary, IEEE VNR SB</td>
            </tr>
            <tr>
                <th scope="row">11</th>
                <td>Bojja Srikar</td>
                <td>Secretary, IEEE VNR CASS SBC</td>
            </tr>
            <tr>
                <th scope="row">12</th>
                <td>Pujika Upadhyay</td>
                <td>Secretary, IEEE VNR WIE AG</td>
            </tr>
            <tr>
                <th scope="row">13</th>
                <td>Sai Kamal Kasanagottu</td>
                <td>Treasurer, IEEE VNR SB</td>
            </tr>
            <tr>
                <th scope="row">14</th>
                <td>Suhas Patlolla</td>
                <td>Student Member</td>
            </tr>
            <tr>
                <th scope="row">15</th>
                <td>Surabhi Seetha</td>
                <td>Student Member</td>
            </tr>
            <tr>
                <th scope="row">16</th>
                <td>Yashaswi Lakku</td>
                <td>Student Member</td>
            </tr>
            </tbody>
        </Table>
        </div>
    </section>
    
  )
