import React from 'react';

export default ()=>(
    <div className="container-fluid p-3" >	       
        <h2>Table of Contents</h2>
        <ul className="list-base ml-4">
            <li><a href="http://www.signalprocessingsociety.org/newsletter">eNewsletter</a></li>
            <li><a href="https://signalprocessingsociety.org/publications-resources/sigport">SigPort</a></li>
            <li><a className="ext" href="http://publications-resources/sps-resource-center" target="_blank" >SPS Resource Center</a></li>
            <li><a href="https://signalprocessingsociety.org/events/special-issue-deadlines">Special Issues</a></li>
            <li><a className="ext" href="http://www.ieee.org/publications_standards/publications/authors/open_access.html" target="_blank" >IEEE Open Access</a></li>
            <li><a className="restricted" href="https://signalprocessingsociety.org/publications-resources/digital-edition-sp-magazine">Digital Edition of SP Magazine</a></li>
            <li><a className="ext" href="http://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=79" target="_blank" >IEEE Signal Processing Magazine</a></li>
            <li><a className="ext" href="http://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=97" target="_blank" >IEEE Signal Processing Letters</a></li>
            <li><a className="ext" href="http://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=4200690" target="_blank" >IEEE Journal of Selected Topics in Signal Processing</a></li>
            <li><a className="ext" href="http://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=10376" target="_blank" >IEEE ACM/Transactions on Audio, Speech, and Language Processing</a></li>
            <li><a className="ext" href="https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=6745852" target="_blank" >IEEE Transactions on Computational Imaging</a></li>
            <li><a className="ext" href="http://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=83" target="_blank" >IEEE Transactions on Image Processing</a></li>
            <li><a className="ext" href="http://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=10206" target="_blank" >IEEE Transactions on Information Forensics and Security</a></li>
            <li><a className="ext" href="https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=6884276" target="_blank" >IEEE Transactions on Signal and Information Processing over Networks</a></li>
            <li><a className="ext" href="http://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=78" target="_blank" >IEEE Transactions on Signal Processing</a></li>
            
        </ul>
	</div>
)