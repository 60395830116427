import React from "react";
import TeamMember from './TeamMember';
import drAynampudi from '../assets/speakers/AynampudiSubbarao.jpg';
import drRamjee from '../assets/speakers/Dr.Ramjee.jpg';
import amitKumar from '../assets/AdvisoryCommittee/AmitKumar.jpg';
import drSubhadra from '../assets/speakers/DrSubhadra.jpg';
import mrNarayanaRao from '../assets/speakers/MrNarayanaRao.jpg';


function Panellists() {
  return (
    <section className="bg-light page-section pb-1 pt-2" id="guests">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Panellists</h2>
          </div>
        </div>
        <div className="row my-3">
            <TeamMember cols={4}
                    src={drAynampudi}
                    alt="one"
                    title="Dr. Aynampudi. Subbarao"
                    designation={`Innovation Consultant, Director Academics, IFIA`}
            />
            <TeamMember cols={4}
                    src={amitKumar}
                    alt="one"
                    title="Dr. Amit Kumar"
                    designation={`Chair - IEEE Hyderabad Section
                    Chairman- BioAxis DNA Research Centre (P) Ltd.`}
            />
            <TeamMember cols={4} 
                    src={drRamjee}
                    alt="one"
                    title="Dr. Ramjee Pallela"
                    designation={`COO at AIC-CCMB`}
            />
          </div>
        <div className="row my-3">
            <div className="col-sm-2"></div>
            <TeamMember cols={4} 
                    src={mrNarayanaRao}
                    alt="one"
                    title="Mr. Narayana Rao Sripada"
                    designation={`Co-founder / Director at Salcit Technologies`}
            />
            <TeamMember cols={4}
                    src={drSubhadra}
                    alt="one"
                    title="Dr. Harivardhagini Subhadra"
                    designation={`Vice-Chair ,WIE AG IEEE Hyderabad Section`}
            />
          </div>

      </div>
    </section>
  );
}

export default Panellists;
