import React from "react";
import { Table } from "reactstrap";
import Speakers from "./Speakers";

function EventDetails() {
  return (
    <div className="container">
      <h1 className="my-4">Event Details</h1>
      <hr className="my-4 " color="primary" />
      <Table hover responsive>
        <tbody>
          <tr>
            <th scope="row">Type of event</th>
            <td>Seasonal School</td>
          </tr>
          <tr>
            <th scope="row">Name of event</th>
            <td>
              IEEE SPS Summer School on Internet of Things for Biomedical and
              Healthcare Applications
            </td>
          </tr>
          <tr>
            <th scope="row">Dates</th>
            <td>28th December - 31st December 2020</td>
          </tr>
          <tr>
            <th scope="row">Key Events</th>
            <td style={{color: '#069',fontWeight: 700, fontSize: '1.2em'}}>
            Technical Sessions

              <br />
              Poster Presentation
              <br />
              Panel Discussion
              <br />
            </td>
          </tr>
        </tbody>
      </Table>
      <h4 className="my-4 font-weight-bold">Technical Description</h4>
      <p>The Internet of Things (IoT) has numerous applications in healthcare—from remote monitoring and
assistive technologies to smart sensors in wearables and medical device integration. It has the
potential not only to keep patients safe and healthy but also to improve the capability of physicians
as well and even to create new possibilities for healthcare delivery. The availability of future 5G
networks and connected systems will enable new forms of remote, real-time interaction—the so-
called &quot;Tactile Internet&quot;—that will allow medical doctors to provide their skills and expertise even
in remote areas, from traditional healthcare and assistance points of delivery.
In this summer school, the instructors will cover the topics of Biomedical Signal Processing and
Healthcare IoT applications, presenting the most recent advances of research in these fields, and
providing the attendees with the necessary skills to address the future challenges related to
biomedical engineering and healthcare.
      </p>
      <h4 className="my-4 font-weight-bold">Topics to be Covered</h4>
      <p>
            • Latest trends in Healthcare technologies 
      <br />• Bio-Medical Signal Processing techniques
      <br />• Main Sources of Noise and artifact removal in Biomedical Signals
      <br />• Fog computing/Edge clouds for Healthcare
      <br />• Sensors for IoT Healthcare
      <br />• Architecture of IoT Healthcare
      <br />• Machine Learning for Breast cancer diagnosis
      <br />• Ethics for Health-care applications
      <br />• Mobile-to-cloud computing (MCC) for Healthcare
      <br />• Wearable IoT sensing for Health-care
      <br />• Deep Learning applications for Health-care
      </p>
      
      <h4 className="my-4 font-weight-bold">
        Organizations Associated
      </h4>
      <p>
        We are inviting the resource persons from the following organizations:
        <br />• IISc Banglore  <br />
              • IIT Hyderabad  <br />
              • IIT Chennai  <br />
              • IIT BHU  <br />
              • IIIT Hyderabad  <br />
              • NIT Patna  <br />
              • JNTU Hyderabad  <br />
              • Osmania University, Hyderabad  <br />
              • Tata Consultancy Services Limited  <br />
              • Blaze  Automation  <br />
              • Niramai Health Analytix Private Limited  <br />
              • Sapien Biosciences Pvt ltd  <br />
              • IFIA  <br />
              • BioAxis DNA Research Centre (P) Ltd  <br />
              • AIC-CCMB  <br />
              • Salcit Technologies  <br />
                    </p>
      
      {/* <Speakers classes="page-section pt-1 pb-1"/> */}
      {/* <h4 className="my-4 font-weight-bold">
        The estimated number of SPS Members this event will impact: 50
      </h4>
      <h4 className="my-4 font-weight-bold">
        Special activities or initiatives that enhance the impact of the event
      </h4>
      <p className="mb-4">
        In addition to the lecture sessions, we have planned <br />
        a. A Panel Discussion will be planned on current trends in Biomedical
        and Healthcare applications and its impact on human lives.
        <br />
        b. Live demonstrations on Signal processing applications by industry
        experts.
        <br />
        c. An exhibit of Hardware projects and Poster Presentation.
        <br className="my-4" />
      </p> */}
    </div>
  );
}

export default EventDetails;
