import React from "react";
import Header from "./Header";
import Cards from "./Cards";
import Speakers from "./Speakers";
import Guests from "./Guests";
import Panellists from "./Panellists";


function HomePage() {
  return (
    <div>
      <Header />
      <Cards />
      <Guests />      
      <Speakers classes="bg-light page-section pt-1 pb-1"/>    
      <Panellists />      
    </div>
  );
}

export default HomePage;
