import React from "react";

export default (props)=>{
    let colClass = props.cols ? "col-sm-"+props.cols :  "col-sm-3";
    return (
    <div className={colClass}>
        <div className="team-member">
            <img
            className="mx-auto rounded-circle"
            src={props.src}
            alt={props.alt}
            />
            <h4>{props.title}</h4>
    <p className="text-muted" style={{whiteSpace: "pre-line"}}>{props.designation}</p>
            {/* <ul className="list-inline social-buttons">
            <li className="list-inline-item">
                <a href="/">
                <i className="fab fa-whatsapp"></i>
                </a>
            </li>
            <li className="list-inline-item">
                <a href="/">
                <i className="fab fa-facebook-f"></i>
                </a>
            </li>
            <li className="list-inline-item">
                <a href="/">
                <i className="fab fa-linkedin-in"></i>
                </a>
            </li>
            </ul> */}
        </div>
    </div>
            )
}