import React from "react";
import TeamMember from './TeamMember';
import guest1 from '../assets/speakers/Guest1_DrRamaSubbaReddy.jpg';
import guest2 from '../assets/speakers/Guest2_DrShivaPrakash.jpg';

function Guests() {
  return (
    <section className="bg-light page-section pb-1 pt-4" id="guests">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Chief Guests</h2>
          </div>
        </div>
        <div className="row my-3">
            <div className="col-sm-2"></div>
            <TeamMember cols={4}
                    src={guest1}
                    alt="one"
                    title="Dr. M. Ramasubba Reddy"
                    designation={`Head of Laboratory, 
                    Biomedical Engineering Group
                    Department of Applied Mechanics
                    Indian Institute of Technology, Chennai`}
            />
            <TeamMember cols={4}
                    src={guest2}
                    alt="one"
                    title="Dr. N. C. Shiva Prakash"
                    designation={`Department of Instrumentation 
                    and Applied Physics
                    Indian Institute of Science`}
            />
          </div>

      </div>
    </section>
  );
}

export default Guests;
