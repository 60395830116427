import React from "react";
import TeamMember from './TeamMember';
import speaker1 from '../assets/speakers/Speaker1_CeliaShahnaz.PNG';
import speaker2 from '../assets/speakers/Speaker2_NeerajSharma.jpg';
import speaker3 from '../assets/speakers/Speaker3_JugnuJain.jpg';
import speaker4 from '../assets/speakers/Speaker4_SivaTeja.jpg';
import speaker5 from '../assets/speakers/Speaker5_SridharCV.PNG';
import mediaChair from '../assets/Team/DrCKiran.jpg';
import speaker6 from '../assets/speakers/Speaker6_PSridhar.jpg';
import drAnitha from '../assets/speakers/DrAnitha.jpg';
import drMalini from '../assets/speakers/MrsMalini.jpeg';
import vamsi from '../assets/speakers/VamsiKotte.jpg';


function Speakers(props) {
  return (
    <section className={props.classes} id="speakers">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Speakers</h2>
          </div>
        </div>
        <div className="row my-3">
            <TeamMember  
                    src={speaker3}
                    alt="one"
                    title="Dr. Jugnu Jain, PhD"
                    designation={`Co-Founder & CEO 
                    Sapien Biosciences, 
                    Hyderabad, India`}
            />
            <TeamMember  
                    src={speaker1}
                    alt="one"
                    title="Dr. Celia Shahnaz"
                    designation={`Professor
                    Department of EEE, Bangladesh University`}
            />
            <TeamMember  
                    src={speaker2}
                    alt="one"
                    title="Dr. Neeraj Sharma"
                    designation={`Professor 
                    School of Biomedical Engineering
                    IIT (BHU), Varanasi India`}
            />
            <TeamMember  
                    src={speaker4}
                    alt="one"
                    title="Shiva Teja Kakileti"
                    designation={`Data Scientist
                    Niramai Health Analytix Pvt Ltd,
                    Banglore, India`}
            />
          </div>
        <div className="row my-3">
            <TeamMember  
                    src={speaker5}
                    alt="one"
                    title="Sridhar CV"
                    designation={`Professor 
                    Head, Alliances, CTO Incubation
                    Tata Consultancy Services,
                    Hyderabad, India`}
            />
            <TeamMember 
                  src={mediaChair}
                  alt="one"
                  title="Dr. C. Kiran"
                  designation={`Designing, Media and Publicity Chair 
                  Professor-EIE, VNRVJIET `}
            />
            <TeamMember 
                  src={speaker6}
                  alt="one"
                  title="Mr. P. Sridhar"
                  designation={`CEO, Blaze Automation Inc
                  Hyderabad, India`}
            />
            <TeamMember 
                  src={drAnitha}
                  alt="one"
                  title="Dr. Anitha Sheela Kancharla"
                  designation={`Professor & Head
                  Electronics & Communication Engineering
                  JNTU Hyderabad`}
            />
          </div>
        <div className="row my-3">
            <div className="col-sm-3"></div>
            <TeamMember  
                    src={drMalini}
                    alt="one"
                    title="Dr. M. Malini"
                    designation={`Professor Dept.of BME, University College of Engineering, 
                    Osmania University, Hyderabad`}
            />
            <TeamMember 
                  src={vamsi}
                  alt="one"
                  title="Mr. Vamsi Kotte"
                  designation={`CEO, Syntizen 
                  Hyderabad, India`}
            />
        </div>
      </div>
    </section>
  );
}

export default Speakers;
