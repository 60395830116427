import React from 'react';
import TeamMember from './TeamMember';
import sessionsChair from '../assets/Team/B.V.Kiranmai.jpg';
import transportChair from '../assets/Team/Dr.Padmasree.jpg';
import generalChair from '../assets/Team/Dr.Y.Padma Sai.jpg';
import mediaChair from '../assets/Team/DrCKiran.jpg';
import technicalChair from '../assets/Team/DrKishore.jpg';
import financeChair1 from '../assets/Team/Manjulasree.jpg';
import financeChair2 from '../assets/Team/Priyanka.jpg';
import organizingChair from '../assets/Team/RajendraPrasad.jpg';
import websiteChair from '../assets/Team/rameshchandra.jpg';

export default ()=>(
    <section className="bg-light page-section pt-2" id="team">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Organizing Committee Chairs</h2>
            
          </div>
        </div>
        <div className="row mt-4">
          <TeamMember 
                src={generalChair}
                alt="one"
                title="Dr. Y. Padma Shayi"
                designation={`General Chair
                Professor & Head-ECE, VNR VJIET
                Chair, IEEE Hyderabad WIE`}
          />
          <TeamMember 
                src={financeChair1}
                alt="one"
                title="Dr. R. Manjula Sri"
                designation={`Finance Committee Chair
                Professor & Head-EIE, VNRVJIET`}
          /> 
          <TeamMember 
                src={sessionsChair}
                alt="one"
                title=" Dr. B. V. Kiranmai"
                designation={`Technical Sessions Chair
                Professor & Head-CSE, VNRVJIET`}
          />
          <TeamMember 
                src={websiteChair}
                alt="one"
                title="Dr. Ramesh Chandra"
                designation={`Website Committee Chair 
                Professor-CSE, VNRVJIET `}
          />
        </div>

        <div className="row">
          <TeamMember 
                src={transportChair}
                alt="one"
                title="Dr. L. Padma Sree"
                designation={`Transport & Accommodation Chair 
                Professor-ECE, VNRVJIET`}
          />
          <TeamMember 
                src={organizingChair}
                alt="one"
                title=" Dr. S. Rajendra Prasad"
                designation={`Local Organizing Committee Chair
                Professor-ECE, VNRVJIET `}
          /> 
          <TeamMember 
                src={technicalChair}
                alt="one"
                title="Dr. P. Kishore"
                designation={`Technical Program Chair 
                Assistant Professor-ECE, VNRVJIET`}
          />
          <TeamMember 
                src={financeChair2}
                alt="one"
                title="Mrs. V. Priyanka "
                designation={`Finance Committee Chair
                Assistant Professor-ECE, VNRVJIET`}
          />
        </div>
        <div className="row">
          <TeamMember 
                src={mediaChair}
                alt="one"
                title="Dr. C. Kiran"
                designation={`Designing, Media and Publicity Chair 
                Professor-EIE, VNRVJIET `}
          />
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <p className="large text-muted">
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
              eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam
              corporis ea, alias ut unde. */}
            </p>
          </div>
        </div>
      </div>
    </section>)