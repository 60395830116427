import React from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Col,
  Row,
  Container
} from "reactstrap";
import hands from '../assets/hands.jpg';
import golconda from '../assets/golconda.jpg';

const Cards = props => {
  return (
    <Container>
      <Row>
        <Col md="6" className="my-4 mx-auto">
          <Card>
            <CardImg
              top
              width="20"
              height="200"
              src={hands}
              alt="Card image cap"
            />
            <CardBody>
              <h3>IEEE Signal Processing Society</h3>
              <CardSubtitle className="mb-2">Signal Processing Society is the world’s premier professional society for signal processing scientists and professionals since 1948</CardSubtitle>
              <a href="https://signalprocessingsociety.org/" target="_blank" className="link-btn">
                <button className="btn btn-sm btn-primary btn-block text-uppercase">VISIT SITE NOW</button>
              </a>
            </CardBody>
          </Card>
        </Col>
        <Col md="6" className="my-4 mx-auto">
        <Card>
            <CardImg
              top
              width="20"
              height="200"
              src={golconda}
              alt="Card image cap"
            />
            <CardBody>
              <h3>IEEE Hyderabad Section</h3>
              <CardSubtitle className="mb-2">The IEEE Hyderabad Section is one of the most active sections of IEEE in India.</CardSubtitle>
              <a href="https://ieeehyderabad.org/" target="_blank" className="link-btn">
                <button className="btn btn-sm btn-primary btn-block text-uppercase">VISIT SITE NOW</button>
              </a>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Cards;
