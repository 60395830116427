import React from 'react';
import { Table } from "reactstrap";
// import TeamMember from './TeamMember';
// import sessionsChair from '../assets/Team/B.V.Kiranmai.jpg';
// import transportChair from '../assets/Team/Dr.Padmasree.jpg';
// import generalChair from '../assets/Team/Dr.Y.Padma Sai.jpg';
// import financeChair1 from '../assets/Team/Manjulasree.jpg';
// import organizingChair from '../assets/Team/RajendraPrasad.jpg';
// import websiteChair from '../assets/Team/rameshchandra.jpg';
// import drChalapathi from '../assets/Team/Dr.Y.Chalapathi Rao.jpg';
// import mrsArchana from '../assets/Team/archana.jpg';
// import mrsKanthi from '../assets/Team/kanthi.jpg';
// import mrAnudeep from '../assets/Team/Anudeep.jpg';
// import mrSravanth from '../assets/Team/Sravanth.JPG';
// import mrsKumari from '../assets/Team/Rajakumari.jpg';


export default ()=>(
    <section className="bg-light page-section pt-2" id="team">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Organizing Committee Members</h2>
            
          </div>
        </div>
        
        <Table hover responsive>
            <tbody>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Designation</th>
            </tr>
            <tr>
                <th scope="row">1</th>
                <td>Dr. Y. Chalapathi Rao</td>
                <td>Associate professor, VNR VJIET</td>
            </tr>
            <tr>
                <th scope="row">2</th>
                <td>Mrs. K. Archana Bhange</td>
                <td>Sr. Assistant Professor, VNR VJIET</td>
            </tr>
            <tr>
                <th scope="row">3</th>
                <td>Dr. L. V. Rajani Kumari</td>
                <td>Assistant professor, VNR VJIET</td>
            </tr>
            <tr>
                <th scope="row">4</th>
                <td>Dr. Ch. Suresh</td>
                <td>Assistant professor, VNR VJIET</td>
            </tr>
            <tr>
                <th scope="row">5</th>
                <td>Mrs. D. Kanthi Sudha</td>
                <td>Assistant Professor, VNR VJIET</td>
            </tr>
            <tr>
                <th scope="row">6</th>
                <td>Mr. P. Anudeep</td>
                <td>Assistant Professor, VNR VJIET</td>
            </tr>
            <tr>
                <th scope="row">7</th>
                <td>Mr. R. Sravanth Kumar</td>
                <td>Assistant Professor, VNR VJIET</td>
            </tr>
            <tr>
                <th scope="row">8</th>
                <td>Mrs. Ch. Raja Kumari</td>
                <td>Assistant Professor, VNR VJIET</td>
            </tr>
            <tr>
                <th scope="row">9</th>
                <td>Mr. Kalyan Srinivas</td>
                <td>Assistant Professor, VNR VJIET</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </section>)