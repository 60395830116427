import React, { useState } from "react";
import IEEESites from './IEEESites';
import ieeeHyd from '../assets/Logos/ieeehyd.png'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

const Navigation = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div >
      <IEEESites />
      <Navbar color="white" light expand="md" className="border-bottom"  >
       
        <a href="/" className="navbar-brand">
          <img
            src="https://signalprocessingsociety.org/sites/all/themes/evolve_sub/assets/images/SPS-logo-new.svg"
            width="120"
            height="100"
            alt=""
            className="d-block align-middle"
          />
        </a>
        <a href="/" className="navbar-brand">
          <img
            src="https://seeklogo.net/wp-content/uploads/2013/03/ieee-vector-logo-400x400.png"
            
            width="120"
            height="100"
            alt=""
            className="d-block align-middle"
          />
        </a>
        <a href="/" className="navbar-brand">
          <img
            src={ieeeHyd}
            width="120"
            alt=""
            className="d-block align-middle"
          />
        </a>

        <NavbarToggler onClick={toggle} className="bg-light" />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink className="text-primary font-weight-bolder" href="/">
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="text-primary font-weight-bolder" href="/about">
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="text-primary font-weight-bolder"
                href="/eventdetails"
              >
                Event Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="text-primary font-weight-bolder"
                href="/registration"
              >
                Registration
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="text-primary font-weight-bolder"
                href="/schedule"
              >
                Schedule
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="text-primary font-weight-bolder" href="/team">
                Team
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className="text-primary font-weight-bolder"
                href="/gallery"
              >
                Gallery
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                className="text-primary font-weight-bolder"
                href="/knowledgebase"
              >
                SPS Knowledge Base
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="text-primary font-weight-bolder"
                href="/contact"
              >
                Contact
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
       
      </Navbar>
    </div>
  );
};

export default Navigation;
