import React from "react";
import TeamMember from './TeamMember';
import president from '../assets/AdvisoryCommittee/President_DNRao.jpg';
import gensec from '../assets/AdvisoryCommittee/GenSec_Harischandra.jpg';
import amitKumar from '../assets/AdvisoryCommittee/AmitKumar.jpg';
import chairLalitha from '../assets/AdvisoryCommittee/Chair_Lalitha.jpg';
import gvvSharma from '../assets/AdvisoryCommittee/GVVSharma.jpg';
import principal from '../assets/AdvisoryCommittee/Principal_CDNaidu.jpg';
import ieeeSec from '../assets/AdvisoryCommittee/SecretaryIEEE_BalaPrasad.jpg';
import treasurer from '../assets/AdvisoryCommittee/Treasurer_NookalaSrinivas.jpg';
import guest1 from '../assets/speakers/Guest1_DrRamaSubbaReddy.jpg';
import directorSandhya from '../assets/AdvisoryCommittee/DrSandhyaKode.JPG';
import chairYP from '../assets/AdvisoryCommittee/ChairYP_VamsiKrishna.jpg';
import drAnuradha from '../assets/AdvisoryCommittee/DrAnuradha.jpg';
import drPoonam from '../assets/AdvisoryCommittee/DrPoonam.jpg';
import director from '../assets/AdvisoryCommittee/Director_VNR.jpg';



function Committee() {
  return (
    <section className="bg-light page-section pb-0" id="committee">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Advisory Committee</h2>
            
          </div>
        </div>
        <div className="row mt-4">
          <TeamMember 
                src={president}
                alt="one"
                title="Dr. D. N. Rao"
                designation={`President, Vignana Jyothi, Hyderabad `}
          />
          <TeamMember 
                src={gensec}
                alt="one"
                title="Sri. K. Harischandra Prasad"
                designation={`General Secretary, Vignana Jyothi, Hyderabad `}
          />
          <TeamMember 
                src={directorSandhya}
                alt="one"
                title="Dr. Sandhya Kode"
                designation={`Director, EnhanceEdu, IIIT Hyderabad  `}
          />
          <TeamMember 
                src={principal}
                alt="one"
                title="Dr. C. D. Naidu"
                designation={`Principal, VNR VJIET, Hyderabad `}
          />
        </div>

        <div className="row">
          <TeamMember 
                src={guest1}
                alt="one"
                title="Dr. M. Rama Subba Reddy"
                designation={`Professor, IIT Chennai  `}
          />
          <TeamMember 
                src={gvvSharma}
                alt="one"
                title="Dr. GVV Sharma "
                designation={`Associate Professor IIT Hyderabad  `}
          />
          <TeamMember 
                src={amitKumar}
                alt="Mr. Amit Kumar"
                title="Mr. Amit Kumar"
                designation={` Chair, IEEE Hyderabad Section`}
          />
          <TeamMember 
                src={ieeeSec}
                alt="one"
                title="Mr. Bala Prasad Peddigari "
                designation={`Secretary IEEE Hyderabad Section`}
          />
        </div>
        <div className="row">
          <TeamMember 
                src={treasurer}
                alt="one"
                title="Dr. Nookala Srinivas "
                designation={`Treasurer, IEEE Hyderabad Section`}
          />
          <TeamMember 
                src={chairLalitha}
                alt=" Dr.Lalitha Vadlamani"
                title=" Dr. Lalitha Vadlamani"
                designation={`Chair, IEEE Hyderabad Section
                Com Soc/SP Societies`}
          />
          <TeamMember 
                src={drAnuradha}
                alt="Dean Academics,VNR VJIET"
                title="Dr. Anuradha"
                designation={`Dean Academics,VNR VJIET`}
          />
          <TeamMember 
                src={director}
                alt=" Dr. B. Chennakesava Rao, Director,VNR VJIET"
                title="Dr. B. Chennakesava Rao"
                designation={`Director,VNR VJIET`}
          />
        </div>
        <div className="row">
          <TeamMember 
                src={drPoonam}
                alt="one"
                title="Dr. Poonam Upadhyay"
                designation={`HoD, Dept.of EEE, VNR VJIET`}
          />
          <TeamMember 
                src={chairYP}
                alt="Mr.Vamsi Krishna J"
                title="Mr. Vamsi Krishna J"
                designation={`Chair,Young Professionals AG, IEEE Hyderabad Section`}
          />
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <p className="large text-muted">
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
              eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam
              corporis ea, alias ut unde. */}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Committee;
